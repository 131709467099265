import React from "react"
import Layout from "../components/Layout/layout"
import PageInfoSection from "../components/PageInfoSection/PageInfoSection"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ServicesSection } from "../components/ServicesSection/ServicesSection"
import { WaveDiv } from "../components/WaveDiv/WaveDiv"
import { ProcessSection } from "../components/ProcessSection/ProcessSection"
import Squares from '../components/Squares/Squares'
import WebfluentBanner from '../components/WebfluentBanner/WebfluentBanner'
import HomeAnimation from '../components/HomeAnimation/HomeAnimation'
import Hero from '../components/Hero/Hero'
import heroImg from '../assets/images/hero.jpeg'
const useStyles = makeStyles({
  aside: {
    padding: "3rem",
  },
})

const IndexPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Hero image={heroImg} />
      <Squares>
        <aside className={classes.aside}>
          <Container>
            <PageInfoSection
              title="we speak the"
              blueTitle="web"
              text="At Webfluent, custom is the only way. We combine our tech savvy talents with your business model to engage with your audience and get you results. Our attention to detail, strategic process, and exemplary implementation are some of the reasons we outperform the rest. A website by us is one of a kind."
            >
              {/* render children */}
              <HomeAnimation />
            </PageInfoSection>
          </Container>
        </aside>
      </Squares>
      <WaveDiv>
        <ServicesSection />
      </WaveDiv>
      <ProcessSection />
      <WebfluentBanner />
    </Layout>
  )
}

export default IndexPage
