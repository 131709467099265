import React from 'react'
import { WaveDiv } from '../WaveDiv/WaveDiv'
import { Grid, Button } from '@material-ui/core'
import WebfluentLogo from '../../assets/images/logo_logo.png';
import { useStyles } from './utils/styles'
const WebfluentBanner = () => {
    const classes = useStyles();
    return (
        <WaveDiv>
            <Grid style={{ textAlign: 'center' }} item xs={12}>
                <img alt='wenfluent banner' className={classes.img} src={WebfluentLogo} />
                <div className={classes.div}>
                    <Button className={classes.buttonLeft}>Get Started</Button>
                    <Button className={classes.buttonRight}>Learn More</Button>
                </div>
            </Grid>
        </WaveDiv>
    )
}

export default WebfluentBanner