import React from "react"
import { useStyles } from "../../utils"
import { Typography, Grid, Divider } from "@material-ui/core"
import { motion, useAnimation } from "framer-motion"
import useInView from "react-cool-inview"

const ProcessItem = ({ textLeft, img, text, title, number, divider, animated }) => {
  const classes = useStyles()
  const controls = useAnimation()
  const { ref } = useInView({
    onEnter: () => {
      controls.start("open")
    },
  })

  const variants = {
    closed: {
      y: 250,
      opacity: 0,
    },
    open: {
      y: 0,
      opacity: 1,
    },
  }
  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial={animated ? "closed" : 'open'}
      animate={animated ? controls : null}
      transition={{ duration: 0.7, delay: 0.3, type: "spring", damping: 9 }
      }
    >
      <Grid container spacing={2}>
        <Grid className={classes.numberGrid} item xs={12}>
          <div className={classes.circle}>
            <Typography variant="h4">{number}</Typography>
          </div>
        </Grid>
        {textLeft ? (
          <Grid className={classes.root} item xs={12} md={8}>
            <>
              <Typography variant="h4"> {title} </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body2"> {text} </Typography>
            </>
          </Grid>
        ) : (
            <Grid className={classes.root} item xs={12} md={4}>
              <img height='200px' src={img} />
            </Grid>
          )}
        {textLeft ? (
          <Grid className={classes.root} item xs={12} md={4}>
            <img height='200px' src={img} />
          </Grid>
        ) : (
            <Grid className={classes.root} item xs={12} md={8}>
              <>
                <Typography variant="h4"> {title} </Typography>
                <Divider className={classes.divider} />
                <Typography variant="body2"> {text} </Typography>
              </>
            </Grid>

          )}
      </Grid>
    </motion.div >
  )
}

export default ProcessItem
