import PlanandPrep from "../../assets/images/plan-and-prep.png"
import DesignAndDevlelop from "../../assets/images/design-and-develop.png"
import { makeStyles } from "@material-ui/core/styles"
export const useStyles = makeStyles(theme => ({
  aside: {
    padding: "3rem",
  },
  paddingY: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "3rem 0rem",
    },
  },
  root: {
    textAlign: "center",
  },
  divider: {
    width: "80%",
    display: "inline-block",
    height: 3,
    backgroundColor: 'rgb(34,134,127)',
    borderRadius: '20px'
  },
  numberGrid: {
    display: "flex",
    justifyContent: "center",
    marginBottom: '3rem',
  },
  number: {
    display: "inline",
  },
  circle: {
    display: "inline",
    backgroundColor: "rgb(37,137,124)",
    padding: [theme.spacing(2)],
    borderRadius: "50%",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "4em",
    height: "4em",
  },
  dividerGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  }
}))
export const processItems = [
  {
    img: DesignAndDevlelop,
    text:
      "To begin, we learn about your business structure and target markets.  Our development team will assess your audiences’ preferences and identify the best method(s) to achieve maximum engagement.",
    title: "Design and Develop",
    textLeft: false,
    divider: true,
  },
  {
    img: PlanandPrep,
    text:
      "Once we have the basics, we create the detailed structure of your project. We build the framework by blending your business goals with the latest consumer preferences. The end of this step yields a modern, easy-to-use interface.",
    title: "Plan and Prep",
    textLeft: true,
    divider: true,
  },
  {
    img: DesignAndDevlelop,
    text:
      "The webfluent team will present the project and guide you through the mechanics. All revisions will be completed. We will help you execute a successful launch so you can watch your investment pay off!",
    title: "Launch it!",
    textLeft: false,
    divider: false,
  },
]
