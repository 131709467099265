import React from "react"
import { Container, Grid, Divider, Hidden } from "@material-ui/core"
import { useStyles, processItems } from "./utils"
import ProcessItem from "./components/ProcessItem/ProcessItem"
import Carousel from "react-material-ui-carousel"
export const ProcessSection = () => {
  const classes = useStyles()
  return (
    <aside className={classes.aside}>
      <Container>
        <div className={classes.paddingY}>
          <Grid container alignItems="center" justify="center" spacing={6}>
            <Hidden smDown>
              {processItems.map((i, ind) => (
                <>
                  <Grid key={ind * -1} style={{ padding: `5rem 0` }} item xs={12}>
                    <ProcessItem
                      animated
                      divider={i.divider}
                      textLeft={i.textLeft}
                      title={i.title}
                      number={ind + 1}
                      text={i.text}
                      img={i.img}
                      key={ind}
                    />
                  </Grid>
                  {i.divider && (
                    <Grid
                      key={ind * -10 + 1}
                      className={classes.dividerGrid}
                      item
                      xs={12}
                    >
                      <Divider key={ind * 10 + 1} className={classes.divider} />
                    </Grid>
                  )}
                </>
              ))}
            </Hidden>
            <Hidden mdUp>
              <Carousel>
                {processItems.map((i, ind) => (
                  <>
                    <Grid key={ind * -1} style={{ padding: `5rem 0` }} item xs={12}>
                      <ProcessItem
                        divider={i.divider}
                        title={i.title}
                        number={ind + 1}
                        text={i.text}
                        img={i.img}
                        key={ind}
                      />
                    </Grid>
                  </>
                ))}
              </Carousel>
            </Hidden>
          </Grid>
        </div>
      </Container>
    </aside>
  )
}
