import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(theme => ({
  img: {
    width: "60%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonLeft: {
    marginRight: [theme.spacing(4)],
    paddingLeft: [theme.spacing(5)],
    paddingRight: [theme.spacing(5)],
    marginTop: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      margin: '16px 0 8px 0'
    },
  },
  buttonRight: {
    marginLeft: [theme.spacing(4)],
    paddingLeft: [theme.spacing(5)],
    paddingRight: [theme.spacing(5)],
    backgroundColor: "#fff",
    color: "#000",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: '8px 0'
    },
  },
  div: {
    paddingTop: [theme.spacing(4)],
    [theme.breakpoints.down("sm")]: {
      paddingTop: [theme.spacing(2)],
    },
  },
}))
