import React from "react"
import { Divider, Grid, Hidden } from "@material-ui/core"
import { services } from "./services"
import ServiceItem from "./components/ServiceItem"
import { makeStyles } from "@material-ui/core/styles"
import Carousel from "react-material-ui-carousel"

const useStyles = makeStyles(theme => ({
  row1: {
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(
      4
    )}px`,
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.spacing(4)}px ${theme.spacing(12)}px ${theme.spacing(
        4
      )}px`,
    },
  },
  row2: {
    padding: `${theme.spacing(6)}px ${theme.spacing(4)}px 0 ${theme.spacing(
      4
    )}px`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(12)}px ${theme.spacing(4)}px 0 ${theme.spacing(
        4
      )}px`,
    },
  },
}))

const row1 = [services[0], services[1], services[2]]
const row2 = [services[3], services[4]]

export const ServicesSection = () => {
  const classes = useStyles()
  return (
    <Grid style={{ overflowX: 'hidden' }} container spacing={4}>
      <Grid item xs={12}>
        <Grid container>
          <Hidden smDown>
            {row1.map((i, ind) => (
              <Grid key={ind} className={classes.row1} item md={4}>
                <ServiceItem
                  animated
                  key={i.name}
                  img={i.img}
                  text={i.text}
                  name={i.name}
                  bolded={i.bolded}
                />
              </Grid>
            ))}
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12}>
              <Carousel
                navButtonsAlwaysInvisible
                autoPlay={true}>
                {services.map(i => (
                  <ServiceItem
                    key={i.name}
                    img={i.img}
                    text={i.text}
                    name={i.name}
                    bolded={i.bolded}
                  />
                ))}
              </Carousel>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12}>
          <Divider style={{ height: '5px', backgroundColor: 'rgb(37,137,124)', borderRadius: '20px' }} />
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid item xs={12}>
          <Grid justify='center' container>
            {row2.map((i, ind) => (
              <Grid key={ind} className={classes.row2} item xs>
                <ServiceItem
                  animated
                  key={i.name}
                  img={i.img}
                  text={i.text}
                  name={i.name}
                  index={ind}
                  bolded={i.bolded}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  )
}
