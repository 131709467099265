import React from 'react'
import Phone from '../../assets/images/mobile.png'
import Desktop from '../../assets/images/desktop.png'
import Laptop from '../../assets/images/laptop.png'
import { Grid } from '@material-ui/core'
import Background from '../../assets/images/background.png'
import { motion, useAnimation } from 'framer-motion'
import useInView from 'react-cool-inview'

const HomeAnimation = () => {
    const controls = useAnimation()

    const { ref } = useInView({
        onEnter: () => {
            controls.start('open')

        },
        rootMargin: '-50px 0px'
    })




    const phoneVariants = {
        closed: {
            x: 100,
            opacity: 0
        },
        open: {
            x: 0,
            opacity: 1
        }
    }

    const backgroundVariants = {
        closed: {
            opacity: 0
        },
        open: {
            opacity: 1
        }
    }

    return (
        <Grid style={{ position: 'relative', overflowX: 'hidden' }} container spacing={2}>
            <motion.div ref={ref} variants={backgroundVariants} initial='closed' animate={controls} transition={{ duration: .3, delay: 0, type: 'spring', damping: 10 }} style={{ background: `url(${Background})  center no-repeat `, backgroundSize: 'contain', position: 'absolute', width: '100%', height: '100%', zIndex: -3 }}></motion.div>
            <Grid style={{ marginRight: '3rem' }} item xs>
                <motion.div ref={ref} variants={phoneVariants} initial='closed' animate={controls} transition={{ duration: .7, delay: .3, type: 'spring', damping: 10 }}>
                    <img src={Phone} alt="" />
                </motion.div>
            </Grid>
            <Grid item xs>
                <motion.div ref={ref} variants={phoneVariants} initial='closed' animate={controls} transition={{ duration: .7, delay: .6, type: 'spring', damping: 10 }}>
                    <img src={Laptop} alt="" />
                </motion.div>
            </Grid>
            <Grid item xs={12}>
                <motion.div ref={ref} variants={phoneVariants} initial='closed' animate={controls} transition={{ duration: .7, delay: .9, type: 'spring', damping: 10 }}>
                    <img src={Desktop} alt="" />
                </motion.div>            </Grid>
        </Grid>
    )
}

export default HomeAnimation
